import urlcat from "urlcat";
import _ from "lodash";

async function fetchData(
  apiKey: string,
  type: string,
  customParameters: Record<string, unknown>
): Promise<{ status: number; result: string }> {
  const url = urlcat(
    `${location.protocol}//${process.env.IS_RC ? "rc" : apiKey}.d41.co/api`,
    {
      req: apiKey,
      form: type,
      ..._.pickBy(customParameters, (_: unknown, key: string) => {
        return key.match(/^p([1-9]|10)$/);
      }),
    }
  );
  try {
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      credentials: "include",
      referrerPolicy: "no-referrer-when-downgrade",
    });
    return {
      status: response.status,
      result: await response.text(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 200,
      result: JSON.stringify({ status: 404, message: "not found" }),
    };
  }
}

type Callback = (response: Record<string, unknown> | string) => void;

function getData(
  apiKey: string,
  type: string,
  responseType: string,
  customParameters: Record<string, unknown>,
  callback: Callback
): void {
  fetchData(apiKey, type, customParameters)
    .then((response) => {
      if (responseType === "raw") {
        callback(response.result);
      } else {
        try {
          callback(JSON.parse(response.result));
        } catch {
          console.error("Could not parse JSON response:", response.result);
          callback({});
        }
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

/* Backwards compatible version for v4 */
declare function getDataBackwardsCompatible(
  apiKey: string,
  type: string,
  responseType: string,
  callback: Callback
): void;

declare global {
  interface Window {
    dnbvid: { getData: typeof getData | typeof getDataBackwardsCompatible };
  }
}

window.dnbvid = process.env.IS_V4
  ? {
      getData: (
        apiKey: string,
        type: string,
        responseType: string,
        callback: Callback
      ) => getData(apiKey, type, responseType, {}, callback),
    }
  : { getData: getData };
